import type { AppProps } from 'next/app';
import App from 'next/app';
import { AppContext } from 'next/dist/pages/_app';
import { ThemeProvider } from 'styled-components';
import { theme } from '@/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AccessDeniedView } from '@/views/AccessDeniedView';
import React, { ReactNode } from 'react';
import { AuthenticationContext, GlobalContext, GlobalProvider, UserContext } from '@/globalContext';

const queryClient = new QueryClient();

type AppPropsWithErr = AppProps & { err: any };

class MyApp extends App<AppPropsWithErr, {}, {}> {
  static defaultProps = {};

  // Added to disable Static Site optimization (missing API_URL from runtimeConfig)
  static async getInitialProps({ Component, ctx }: AppContext) {
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    return { pageProps };
  }

  render() {
    let { Component, pageProps, err } = this.props;
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <DisableSSR>
            <GlobalProvider>
              <HasAccessToPage>
                <Component {...pageProps} err={err} />
              </HasAccessToPage>
            </GlobalProvider>
          </DisableSSR>
          <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
        </ThemeProvider>
      </QueryClientProvider>
    );
  }
}

function DisableSSR({ children }: { children: ReactNode }) {
  return <div suppressHydrationWarning>{typeof window === 'undefined' ? null : children}</div>;
}

const InformationBox = ({ data }: { data: string }) => {
  return (
    <div>
      <h1>{data}</h1>
    </div>
  );
};

function HasAccessToPage({ children }: { children: ReactNode }) {
  const { isWhoAmILoading, isWhoAmIError, whoAmIData } = React.useContext(AuthenticationContext);

  if (isWhoAmILoading) return <InformationBox data='Loading user authentication data...' />;
  if (isWhoAmIError) return <InformationBox data='Cannot fetch user authentication data...' />;

  if (whoAmIData?.authenticated === true && whoAmIData.user) {
    return <GetUserData>{children}</GetUserData>;
  }

  return <AccessDeniedView />;
}

function GetUserData({ children }: { children: ReactNode }) {
  const { selectedWarehouseData, isDataLoading } = React.useContext(GlobalContext);
  const { userData, isUserDataError } = React.useContext(UserContext);

  if (isDataLoading) return <InformationBox data='Loading data...' />;
  if (isUserDataError) return <InformationBox data='Cannot fetch user data...' />;
  if (!userData) return <InformationBox data='No user data found' />;
  if (!userData.warehouses.length || !selectedWarehouseData)
    return <InformationBox data='User not attached to any warehouse' />;

  return <>{children}</>;
}

export default MyApp;
