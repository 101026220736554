import { useQuery } from '@tanstack/react-query';
import getConfig from 'next/config';
import { ParcelRegistrationAPIClient } from '@schibsted-distribution/parcel-registration-api-client';

const { publicRuntimeConfig } = getConfig();

const { API_URL } = publicRuntimeConfig;
const apiClient = new ParcelRegistrationAPIClient({ baseURL: API_URL });

export const useGetWarehouses = (readyForFetch: boolean = false) => {
  return useQuery(['warehouses'], () => apiClient.getWarehouses(), {
    enabled: readyForFetch,
    refetchOnWindowFocus: false
  });
};
