export const blue = '#475467';
export const green = '#12B76A';
export const lightGreen = '#ECFDF3';
export const darkGreen = '#027A48';
export const yellow = '#FCD610';
export const red = '#F04438';
export const gray = '#D0D5DD';
export const darkGray = '#344054';
export const blueGray = '#DBE1E8';
export const lightGray = '#667085';
export const opacityBlack = 'rgba(0, 0, 0, 0.2)';
export const black = '#000000';
export const buttonBlue = '#0077FF';
export const borderBlue = '#026AA2';
export const barBlue = '#065986';
export const white = '#FFFFFF';
export const dirtyWhite = '#fcfdff';
