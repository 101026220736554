import React, { useContext } from 'react';
import getConfig from 'next/config';
import styled from 'styled-components';
import { buttonBlue } from '@/theme/colors';
import { primaryFont, Title } from '@/theme/typography';
import { AuthenticationContext } from '@/globalContext';

const Container = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  height: 95vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h3 {
    text-align: center;
  }
`;

export const LoginButton = styled.a`
  padding: 20px;
  background: ${buttonBlue};
  font-family: ${primaryFont};
  color: white;
  border-radius: 5px;
  width: 200px;
  margin: 50px auto;
  text-decoration: none;
  text-align: center;
`;

const { publicRuntimeConfig } = getConfig();
export function AccessDeniedView() {
  const { whoAmIData } = useContext(AuthenticationContext);

  return (
    <Container>
      <Title>
        {whoAmIData?.authenticated ? 'This user does not have required data to proceed' : 'You are not logged in'}
      </Title>
      <LoginButton href={`${publicRuntimeConfig.AUTH_URL}`}>You can log in here</LoginButton>
    </Container>
  );
}
