import { useQuery } from '@tanstack/react-query';
import getConfig from 'next/config';
import { ParcelRegistrationAPIClient } from '@schibsted-distribution/parcel-registration-api-client';

const { publicRuntimeConfig } = getConfig();

const { API_URL } = publicRuntimeConfig;
const apiClient = new ParcelRegistrationAPIClient({ baseURL: API_URL });

const WHOAMI_QUERY_KEY = 'WHOAMI';

export const useWhoami = () =>
  useQuery([WHOAMI_QUERY_KEY], () => apiClient.whoami(), { retry: false, refetchOnWindowFocus: false });
