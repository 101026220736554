import { css, DefaultTheme, ThemedCssFunction } from 'styled-components';

const breakpointsValues = {
  mobileMaxWidth: 767,
  tabletMaxWidth: 1024
};
type CssReturnType = ReturnType<ThemedCssFunction<DefaultTheme>>;
const tablet: (...args: Parameters<typeof css>) => CssReturnType = (...args) => css`
  @media screen and (min-width: ${breakpointsValues.mobileMaxWidth + 1}px) {
    ${css(...args)};
  }
`;
const desktop: (...args: Parameters<typeof css>) => CssReturnType = (...args) => css`
  @media screen and (min-width: ${breakpointsValues.tabletMaxWidth + 1}px) {
    ${css(...args)};
  }
`;

export const breakpoints = {
  breakpoints: breakpointsValues,
  tablet,
  desktop
};
