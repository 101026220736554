import React, { ReactNode, useEffect, useState } from 'react';
import { useWhoami } from '@/hooks/auth';

import {
  DirectionEnum,
  UserRole,
  UserWithWarehouses,
  Warehouse,
  WorkStation
} from '@schibsted-distribution/parcel-registration-api-client';
import { useGetWarehouses } from '@/hooks/warehouses';
import { useGoogleMapsApi } from './hooks/googleApi';

type WhoAmIDataType = {
  authenticated: boolean;
  role?: string;
  user?: UserWithWarehouses;
};

export type UserWithWarehousesData = Omit<UserWithWarehouses, 'warehouses'> & {
  warehouses: Warehouse[];
};

type UserContextType = {
  userData: UserWithWarehousesData;
  isUserDataError: boolean;
};

type GlobalContextType = {
  isDataLoading: boolean;
  selectedWarehouseData: Warehouse | null;
  selectedWorkStationData?: WorkStation | null;
  setSelectedWorkStationData: (id?: WorkStation) => void;
  scanningMode: string;
  setScanningMode: (mode: string) => void;
};

export const ScanningMode = {
  ...DirectionEnum,
  COMMANDS: 'COMMANDS',
  ASSOCIATION: 'ASSOCIATION'
};

const defaultGlobalContext = {
  isDataLoading: false,
  selectedWarehouseData: null,
  selectedWorkStationData: null,
  setSelectedWorkStationData: () => {},
  scanningMode: typeof ScanningMode.COMMANDS,
  setScanningMode: () => {}
};

type AuthenticationContextType = {
  isWhoAmILoading: boolean;
  isWhoAmIError: boolean;
  whoAmIData: WhoAmIDataType;
};

const defaultAuthenticationContext = {
  isWhoAmILoading: false,
  isWhoAmIError: false,
  whoAmIData: {
    authenticated: false
  },
  user: {
    id: '',
    role: UserRole.OPERATOR,
    email: '',
    created: new Date(),
    updated: new Date(),
    warehouses: []
  }
};

const defaultUserContext = {
  isUserDataError: false,
  userData: {
    id: '',
    role: UserRole.OPERATOR,
    email: '',
    created: new Date(),
    updated: new Date(),
    warehouses: []
  }
};

const GlobalContext = React.createContext<GlobalContextType>(defaultGlobalContext);
const AuthenticationContext = React.createContext<AuthenticationContextType>(defaultAuthenticationContext);
const UserContext = React.createContext<UserContextType>(defaultUserContext);

const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const { data: whoAmIData, isLoading: isWhoAmILoading, isError: isWhoAmIError, isFetched: isWhoAmIFetched } = useWhoami();
  const { isLoading: isGoogleMapsApiLoading } = useGoogleMapsApi();
  const { data: warehouses, isLoading: isWarehousesLoading } = useGetWarehouses(
    isWhoAmIFetched && whoAmIData?.authenticated
  );
  const [selectedWorkStationData, setSelectedWorkStationData] = useState<WorkStation>();
  const [scanningMode, setScanningMode] = useState<string>(ScanningMode.COMMANDS);
  const selectedWarehouseData =
    warehouses?.find(
      warehouse =>
        whoAmIData &&
        whoAmIData.authenticated &&
        whoAmIData.user &&
        whoAmIData.user.warehouses.indexOf(warehouse.name) !== -1
    ) || null;
  const [userData, setUserData] = useState<UserWithWarehousesData | null>(null);

  useEffect(() => {
    setSelectedWorkStationData(selectedWarehouseData?.workstations[0]);
    if (warehouses) {
      const userWithWarehousesData = warehouses.filter(warehouse =>
        whoAmIData && whoAmIData.authenticated && whoAmIData.user
          ? whoAmIData.user.warehouses.includes(warehouse.name)
          : false
      );
      if (whoAmIData && whoAmIData.authenticated && whoAmIData.user)
        setUserData({ ...whoAmIData.user, warehouses: userWithWarehousesData });
    }
  }, [whoAmIData, warehouses, selectedWarehouseData]);

  if (!whoAmIData) return null;
  const isDataLoading = isWarehousesLoading || isWhoAmILoading || isGoogleMapsApiLoading;

  return (
    <AuthenticationContext.Provider value={{ whoAmIData, isWhoAmILoading, isWhoAmIError }}>
      <GlobalContext.Provider
        value={{
          isDataLoading,
          selectedWorkStationData,
          selectedWarehouseData,
          setSelectedWorkStationData,
          scanningMode,
          setScanningMode
        }}
      >
        {userData ? (
          <UserContext.Provider value={{ userData, isUserDataError: isWhoAmIError }}>{children}</UserContext.Provider>
        ) : (
          children
        )}
      </GlobalContext.Provider>
    </AuthenticationContext.Provider>
  );
};

export { GlobalContext, AuthenticationContext, UserContext, GlobalProvider };
