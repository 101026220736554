import styled from 'styled-components';

export const primaryFont = '"Inter", sans-serif';
/**
 * @link https://type-scale.com/ Perfect Four
 */
export const typeScale = {
  header1: '4.209rem',
  header2: '3.157rem',
  header3: '2.369rem',
  header4: '1.777rem',
  header5: '1.333rem',
  paragraph: '1rem',
  small: '0.75rem',
  mini: '0.563rem',
  tiny: '0.422rem'
};

export const Title = styled.h3`
  font-size: ${typeScale.header3};
  font-family: ${primaryFont};
`;

export const Caption = styled.p`
  font-size: ${typeScale.small};
  font-family: ${primaryFont};
`;
